<template>
  <div>
    <b-modal id="modalMultiPackage" centered modal-class="custom-dialog dialog-400" ok-variant="warning" ok-title="Actualizar" ok-only
    :title="$t('Cambiar a multibulto')" no-close-on-esc no-close-on-backdrop @ok="ok" @close="close" :ok-disabled="loading">
      <div class="mt-0 px-1">
        <form-render class="my-1" :fields="fields" :key="keyFormRender" :form.sync="form" ref="formRender" @send="onAccept" containerButtonsClass="vx-col w-full md:w-1/4 container-button"></form-render>
      </div>
    </b-modal>
   
  </div>
</template>
<script>
export default {
  props: ['nPackages', 'loading'],
  data () {
    return {
      form: {},
      keyFormRender: 0,
      fields: [
        { fieldType: 'FieldInput', name: 'n_packages', label: this.$t('Numero de bultos'), containerClass: 'vx-col w-full container-info md:w-1/2'}
      ]
    }
  },
  watch: {
    nPackages () {
      this.form = {
        n_packages: this.nPackages
      }
    }
  }, 
  mounted () {
    if (this.nPackages) {
      this.form = {
        n_packages: this.nPackages
      }
    } 
  },
  methods: {
    ok (e) {
      e.preventDefault()
      this.$refs.formRender.checkForm()
    },
    close () {
      // this.$emit('update:form', {})
    },
    onAccept () {
      const payload = {
        n_packages: Number(this.form.n_packages)
      }
      this.$emit('send', payload)
    }
  }
}
</script>
<style lang="scss">
    
</style>