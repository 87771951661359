<template lang="">
  <div>
    <table-render v-if="!loading.first" id="status_history" :show-checkboxes="false" :schema="schema" :rows="rows"/>
    <div class="table-render-skeleton" v-show="loading.first">
      <b-skeleton-table
        :rows="4"
        :columns="schema.length || 4"
        :table-props="{}"
      />
    </div>
  </div>
</template>
<script>
import moment from 'moment-timezone'
export default {
  name: 'status-history',
  props: ['returnData'],
  data () {
    return {
      rows: [],
      schema: [],
      loading: {
        first: true
      }
    }
  },
  watch: {
    returnData () {
      this.generateRows(this.returnData)
      this.loading.first = false
    }
  },
  mounted () {
    this.schema = [
      {label: 'Estado', key: 'name'},
      {label: 'Fecha', key: 'created_at'},
      {label: 'Comentario', key: 'comment', class: ['w-40']},
      {label: 'Usuario', key: 'user'}
    ]
    this.generateRows(this.returnData)
    this.loading.first = false
  },
  methods: {
    generateRows (returnData) {
      this.rows = (returnData.tracking || []).map((el) => {
        return {
          ...el,
          created_at: this.$options.filters.moment(moment(el.date).tz(returnData.countryTimezone), 'YYYY-MM-DD HH:mm:ss') || this.$t('Sin información'),
          comment: el.extra_data?.dispatch_errors && el.extra_data?.dispatch_errors.length !== 0 ? `${el.info} - ${JSON.stringify(el.extra_data?.dispatch_errors)}` : el.info,
          user: el?.owner_email || 'tracking@enviame.io'
        }
      })
    }
    // this.rows = (statuses || []).map((el, index) => {
    //   const arr = el.date.split('-').join(' ').split(' ').join(':').split(':')
    //   const date = new Date(arr[0], parseInt(arr[1]) - 1, arr[2], arr[3], arr[4], arr[5])
    //   return {...el, row_id: index, ts: date.getTime()}
    // }).sort((a, b) => a.ts - b.ts).reverse()
  }
}
</script>
<style lang="">
  
</style>