<template>
  <div>
    <b-card class="mb-1">
      <b-row class="p-1">
        <b-col cols="6" md="4" lg="3" xl="3" v-for="(num, index) in [1, 2, 3, 4]" :key="index">
          <div class="d-flex">
            <div>
            <b-skeleton type="avatar"></b-skeleton>
            </div>
            <div class="flex-fill ml-1">
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton></b-skeleton>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="p-1">
        <b-col cols="12" class="mt-2 ml-1 ml-md-0">
          <div class="wraper-step-load">
            <ul class="container-step-load">
              <li class="link" v-for="(num, index) in [1, 2, 3, 4]" :key="index"><b-skeleton :width="num === 2 ? '35%': '55%'" class="mx-md-auto mt-md-1"></b-skeleton></li>
            </ul>
          </div>          
        </b-col>
      </b-row>
    </b-card>
    <div class="my-1 d-flex justify-content-end">
      <b-skeleton type="button" width="13%"></b-skeleton>
    </div>
    <b-card class="mb-1">
      <div class="table-render-skeleton">
        <b-skeleton-table
          :rows="4"
          :columns="4"
          :table-props="{}"
        />
      </div>
    </b-card>
  </div>
</template>
<script>

export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style lang="scss">
.wraper-step-load {
  text-align: center;
  width: 600px;
  margin: 10px auto;
}

.container-step-load {
  border-top: 2px solid #e0e0e0;
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;

  .link {
    position: relative;
    margin-top: 10px;
    width: 100%;
  }

  .link:first-child {
    margin-left: -100px;
  }

  .link:last-child {
    margin-right: -100px;
  }

  .link::after {
    content: "";
    width: 20px;
    height: 20px;
    background: #e0e0e0;
    position: absolute;
    border-radius: 10px;
    top: -20px;
    transform: translatex(-50%);
  }

}



@media (max-width: 768px) {
  .wraper-step-load {
    margin: 0;
  }
  .container-step-load {
    display: block;
    border: none;
    justify-content: flex-start;
    border-left: 2px solid #e0e0e0;
    height: 150px;
    .link {
      width: 50%;
      padding: 5px 0;
      display: flex;
      div {
        margin-left: 15px;
      }
    }
    .link:first-child {
      margin-left: 0px;
    }
    .link::after {
      top: 0;
    }

  }
}

</style>