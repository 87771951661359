<template>
  <div>
    <table-render
      :rows="rows"
      :schema="schema"
      :show-checkboxes="false"
      :stickyHeader="`calc(100vh - 2.5rem)`"
      id="log_tracking">
        <template #delivery_carrier_status="scope">
          {{scope.rowdata.delivery_carrier_status}}
          <a :id="`tooltip-${scope.rowdata.delivery_status_id}`" v-if="!!scope.rowdata.tracking_json_data" >
            <feather-icon
              icon="EyeIcon"
              size="14"
              style="color: #337ab7"
            />
          </a>
          <b-tooltip show :target="`tooltip-${scope.rowdata.delivery_status_id}`" placement="right">
            <p>Detalle del Log Tracking</p>
            {{scope.rowdata.tracking_json_data}}
          </b-tooltip>
        </template>
        <template #origin="scope">
          {{scope.rowdata.origin || 'Sin origen definido'}}
        </template>
        <template #tracking_key="scope">
          {{scope.rowdata.tracking_key || 'Sin Clave Definida'}}
        </template>
        <template #comment="scope">
          {{scope.rowdata.comment || 'Sin comentario'}}
        </template>
    </table-render>
  </div>
</template>

<script>
import TableRender from '@/views/components/custom/table-render/TableRender.vue'
import { mapGetters } from 'vuex'
export default {
  components: { TableRender },
  name: 'log-tracking',
  data() {
    return {
      schema: [],
      rows: []
    }
  },
  computed: {
    ...mapGetters({
      theReturn: 'getReturn'
    })
  },
  watch: {
    theReturn() {
      this.setInitialData()
    }
  },
  methods: {
    setInitialData () {
      this.schema = [
        {label: 'Estado Carrier', sortable: true, key: 'delivery_carrier_status', useSlot: true},
        {label: 'Origen', sortable: true, key: 'origin', useSlot: true},
        {label: 'OT del Registro', sortable: true, key: 'tracking_key', useSlot: true},
        {label: 'Fecha de Estado', sortable: true, key: 'created_at'},
        {label: 'Fecha de Tracking', sortable: true, key: 'tracking_date'},
        {label: 'Estado Envíame', sortable: true, key: 'delivery_status_name'},
        {label: 'Comentario', sortable: true, key: 'comment', useSlot: true, style: { width: '15%' }}
      ]
      this.rows = (this.theReturn?.tracking_log || []).map((el, index) => ({
        ...el,
        delivery_status_id: index,
        tracking_json_data: el.detail,
        delivery_carrier_status: el.carrier_status,
        origin: el.origin,
        tracking_key: el.tracking_number,
        delivery_status_name: el.status_name,
        comment: el.info,
        tracking_date: this.$options.filters.moment(el.carrier_date, 'YYYY-MM-DD HH:mm:ss'),
        created_at: this.$options.filters.moment(el.status_date, 'YYYY-MM-DD HH:mm:ss')
      }))
    }
  }
}
</script>