<template>
  <div>
   <create-view-ticket :source="'return'" :ticketIdModal="deliveryId"></create-view-ticket>
  </div>
</template>
<script>
import CreateViewTicket from '@/views/modules/tickets/components/CreateTickets/CreateViewTicket.vue'
export default {
  components: { CreateViewTicket },
  props: { delivery: { type: Object, default: null }},
  computed: {
    deliveryId () {
      return Number(this.delivery?.id)
    },
    source () {
      return this.delivery.type
    }
  }
}
</script>
<style lang="scss">
  
</style>