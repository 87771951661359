<template lang="">
  <b-col cols="12" md="6" class="ml-1">
    <table-render id="advanced" :show-checkboxes="false" :schema="schema" :rows="rows" :actions="actions"/>
  </b-col>
</template>
<script>
export default {
  name: 'advanced',
  props: {
    dispatches: Array
  },
  data () {
    return {
      rows: [],
      schema: [],
      actions: []
    }
  },
  watch: {
    dispatches () {
      this.generateRows(this.dispatches)
    }
  },
  mounted () {
    this.schema = [
      {label: this.$t('Relevantes a la creación del envío'), key: 'name', width:150},
      {label: this.$t('Ver registro'), key: 'actions'}
    ]
    if (this.dispatches) this.generateRows(this.dispatches)
  },
  methods: {
    generateRows (returnData) {
      this.actions = [
        { action: (id) => window.open(returnData[0].carrier_response[id], '_blank'), icon: 'LinkIcon', color: 'warning', text: 'Ver registro' }
      ]
      returnData.map(dispatch => {
        if (dispatch.carrier_response.stackDriver_link) {
          this.rows.push({ id: 'stackDriver_link', name: 'stackDriver_link'})
        }
        if (dispatch.carrier_response.bucket_creation_url) {
          this.rows.push({ id: 'bucket_creation_url', name: 'bucket_creation_url'})
        }
      })
    }
  }
}
</script>
<style lang="">
  
</style>