<template>
  <div>
    <b-modal id="modalReturnStatus" centered modal-class="custom-dialog dialog-400" hide-footer
    title="Cambiar estado de devolución" no-close-on-esc no-close-on-backdrop @close="close">
      <div class="mt-0">
        <form-render :fields="fields" :key="keyFormRender" :form.sync="form"  @send="onAccept" ref="formRenderReturnStatus" containerButtonsClass="vx-col w-full"></form-render>
      </div>
      <div class="d-flex justify-content-end">
        <b-button variant="warning" :disabled="loading" @click="(e) => ok(e)">
          <i class="fa fa-spinner fa-spin mr-1" aria-hidden="true" v-show="loading"></i>{{$t("OK")}}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: 'modal-return-status',
  props: ['statuses', 'loading', 'returnID'],
  data () {
    return {
      form: {},
      keyFormRender: 0,
      fields: []
    }
  },
  watch: {
    statuses () {
      this.returnStatuses = this.statuses
      this.setSelectOptions('return_status', this.fields, this.returnStatuses)
    }
  }, 
  mounted () {
    this.setinitialData()
  },
  methods: {
    setinitialData () {
      this.fields = [
        { fieldType: 'FieldSelect', name: 'return_status', label: this.$t('Nuevo estado'), containerClass: 'col-sm-6 container-info', validation: 'required'},
        { fieldType: 'FieldTextarea', name: 'information', label: this.$t('Comentarios'), containerClass: 'col-sm-12 container-info', validation: 'required' }
      ]
      if (this.statuses) this.setSelectOptions('return_status', this.fields, this.returnStatuses)
    },
    setSelectOptions (name, fields, options) {
      const index = fields.findIndex(el => el.name === name)
      if (index !== -1) {
        fields[index].options = options
      }
      this.keyFormRender++
    },
    ok (e) {
      e.preventDefault()
      this.$refs.formRenderReturnStatus.checkForm()
    },
    close () {
      this.form = {}
    },
    onAccept () {
      const payload = {
        id: this.returnID,
        status_id: this.form.return_status.id,
        info: this.form.information ? this.form.information : ''
      }
      this.$emit('send', payload)
    }
  }
}
</script>
<style lang="scss">
    
</style>